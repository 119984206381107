import styles from './style.module.scss';

function Button({ title, type, icon, fontsize, fontfamlity}: { title: string, type: string, icon?: string, fontsize?: string, fontfamlity?: string}){
    let style;
    if(type === 'secondary'){
        style = styles.secondary;
    }
    if(type === 'primary'){
        style = styles.primary;
    }
    if(icon === 'steam'){
        return <button className={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.4751 8C14.4751 11.5896 11.5647 14.5 7.97507 14.5C4.84315 14.5 2.22907 12.284 1.61374 9.33413L4.19424 10.4191C4.34861 11.1996 5.03924 11.7987 5.88153 11.7917C6.81482 11.7825 7.5634 11.0258 7.56936 10.0968L7.57315 10.0952L9.52423 8.64133C10.7722 8.65433 11.7667 7.64846 11.7667 6.42538C11.7667 5.20121 10.7733 4.20833 9.54969 4.20833C8.33961 4.20833 7.35703 5.17846 7.33428 6.38313L5.90428 8.3835C5.54244 8.37592 5.2169 8.47504 4.93415 8.65975L1.5249 7.2265C1.9084 4.00196 4.64761 1.5 7.97507 1.5C11.5647 1.5 14.4751 4.41038 14.4751 8ZM5.49586 10.9667L4.65953 10.6152C4.86536 11.0799 5.32903 11.4065 5.87774 11.4044C6.60682 11.3979 7.18965 10.8031 7.18315 10.0746C7.17665 9.35471 6.58515 8.76917 5.86582 8.76917C5.71036 8.76971 5.55707 8.79625 5.39999 8.85583L6.23524 9.20683C6.72165 9.41158 6.94969 9.97112 6.74494 10.457C6.54128 10.9423 5.98174 11.1709 5.49586 10.9667ZM9.54969 7.93229C8.71932 7.93229 8.04332 7.25575 8.04332 6.42538C8.04332 5.59446 8.71932 4.91846 9.54969 4.91846C10.3806 4.91846 11.0561 5.59446 11.0561 6.42538C11.0561 7.25575 10.3806 7.93229 9.54969 7.93229ZM9.54969 7.56288C10.1775 7.56288 10.6872 7.05263 10.6872 6.42483C10.6872 5.79758 10.178 5.28625 9.54969 5.28625C8.92136 5.28625 8.41111 5.79704 8.41111 6.42483C8.41165 7.05263 8.9219 7.56288 9.54969 7.56288Z" fill="#F2F2F7"/>
            </svg>
        {title}</button>
    }
    else if(icon === 'AltV'){
        return <button className={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6905 1.55934L2.10226 0.0135991C1.42387 -0.0856584 0.931955 0.369066 1.0077 1.03024L2.25183 11.8479C2.32675 12.4993 2.93214 13.0721 3.60042 13.1276L14.0309 13.996C14.6295 14.0461 15.0588 13.5914 14.9934 12.9808L13.9073 2.84039C13.8409 2.2216 13.2976 1.64793 12.6905 1.55934ZM8.00004 9.68451L4.91021 4.32023H5.9427L8.00004 7.88912L9.53949 5.22503H8.51492L8.00004 6.11179L6.97274 4.32023H11.0899L8.00004 9.68451Z" fill="#F2F2F7"/>
            </svg>
        {title}</button>
    }
    else if(icon === 'Copy'){
        return <button className={style}>
            <svg width="16" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 2H5.5C5.36739 2 5.24021 2.05268 5.14645 2.14645C5.05268 2.24021 5 2.36739 5 2.5V5H2.5C2.36739 5 2.24021 5.05268 2.14645 5.14645C2.05268 5.24021 2 5.36739 2 5.5V13.5C2 13.6326 2.05268 13.7598 2.14645 13.8536C2.24021 13.9473 2.36739 14 2.5 14H10.5C10.6326 14 10.7598 13.9473 10.8536 13.8536C10.9473 13.7598 11 13.6326 11 13.5V11H13.5C13.6326 11 13.7598 10.9473 13.8536 10.8536C13.9473 10.7598 14 10.6326 14 10.5V2.5C14 2.36739 13.9473 2.24021 13.8536 2.14645C13.7598 2.05268 13.6326 2 13.5 2ZM10 13H3V6H10V13ZM13 10H11V5.5C11 5.36739 10.9473 5.24021 10.8536 5.14645C10.7598 5.05268 10.6326 5 10.5 5H6V3H13V10Z" fill="#F2F2F7"/>
            </svg>

        {title}</button>
    }
    return <button style={{fontSize: fontsize, fontFamily: fontfamlity}} className={style}>{title}</button>
}

export default Button;